import Vue from "vue";
import VueRouter from "vue-router";
// import {component} from "vue/types/umd";

// import comload from "@/components/compConstruction.vue";

Vue.use(VueRouter);

const mainRoutes = [
    {
        path: "/",
        name: "home",
        meta: {title: "Главная"},
        component: () => import(/* webpackChunkName: "home" */ "../views/home/home.vue"),
    },
    {
        path: "/news",
        name: "news",
        meta: {title: "Новости"},
        component: () => import(/* webpackChunkName: "news" */ "../views/news/news.vue"),
    },
    {
        path: "/news/:id",
        name: "news-inner",
        meta: {title: "Новость"},
        component: () => import(/* webpackChunkName: "singlenews" */ "../views/news/newsSingle.vue"),
    },
    {
        path: "/team",
        name: "team",
        meta: {title: "Наша команда"},
        component: () => import(/* webpackChunkName: "team" */ "../views/team/team.vue"),
    },
    {
        path: "/reviews",
        name: "reviews",
        meta: {title: "Отзывы"},
        component: () => import(/* webpackChunkName: "reviews" */ "../views/reviews/reviews.vue"),
    },
    {
        path: "/history",
        name: "history",
        meta: {title: "Наша история"},
        component: () => import(/* webpackChunkName: "history" */ "../views/history/history.vue"),
    },
    {
        path: "/contacts",
        name: "contacts",
        meta: {title: "Контакты"},
        component: () => import(/* webpackChunkName: "contacts" */ "../views/contacts/contacts.vue"),
    },
];

const servicesRoutes = [
    {
        path: "/individuals",
        name: "individuals",
        meta: {title: "Индивидуальные туры"},
        component: () => import(/* webpackChunkName: "individuals" */ "../views/individuals/individuals.vue"),
    },
    {
        path: "/visasupport",
        name: "visasupport",
        meta: {title: "Визовая поддержка"},
        component: () => import(/* webpackChunkName: "visasupport" */ "../views/visasupport/visasupport.vue"),
    },
    {
        path: "/corporate",
        name: "corporate",
        meta: {title: "Корпоративные поездки"},
        component: () => import(/* webpackChunkName: "corporate" */ "../views/corporate/corporate.vue"),
    },
];

const toursRoutes = [
    {
        path: "/tours/alltours",
        name: "alltours",
        meta: {title: "Все туры"},
        component: () => import(/* webpackChunkName: "alltours" */ "../views/tours/tours.vue"),
    },
    {
        path: "/tours/seasontours",
        name: "seasontours",
        meta: {title: "Сезонные туры"},
        component: () => import(/* webpackChunkName: "seasontours" */ "../views/tours/toursSeasonal.vue"),
    },
    {
        path: "/tours/toptours",
        name: "toptours",
        meta: {title: "Горячие туры"},
        component: () => import(/* webpackChunkName: "toptours" */ "../views/tours/toursTop.vue"),
    },
    {
        path: "/tours/excursiontours",
        name: "excursiontours",
        meta: {title: "Экскурсионные туры"},
        component: () => import(/* webpackChunkName: "excursiontours" */ "../views/tours/toursExcursion.vue"),
    },
];

const routes = [
    {
        path: "/construction",
        name: "construction",
        meta: {title: "Страница на стадии разработки"},
        component: () => import(/* webpackChunkName: "construction" */ "@/components/compConstruction.vue"),
    },
    {
        path: "/development",
        name: "development",
        meta: {title: "Разработка"},
        component: () => import(/* webpackChunkName: "development" */ "../views/dev/dev.vue"),
    },
    {
        path: "*",
        name: "notfound",
        meta: {title: "Упс"},
        component: () => import(/* webpackChunkName: "notfound" */ "@/components/compNotFound.vue"),
    },
];

const router = new VueRouter({
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return {x: 0, y: 0};
        }
    },
    mode: "history",
    base: process.env.BASE_URL,
    routes: [...mainRoutes, ...servicesRoutes, ...toursRoutes, ...routes],
});

export default router;
